<template>
  <quill-editor
      v-model="editorValue"
      :options="editorOptions"
  >
    <div
        :id="this.getId()"
        slot="toolbar"
    >
      <!-- Add a bold button -->
      <span class="ql-format">
        <button class="ql-bold">

        </button>
        <button class="ql-italic">
          {{ $t('quill.bold') }}
        </button>
        <button class="ql-underline">
          {{ $t('quill.underline') }}
        </button>
        <button class="ql-strike">
          {{ $t('quill.strike') }}
        </button>
      </span>

      <span class="ql-format">
        <button type="button" class="ql-blockquote"/>
        <button type="button" class="ql-code-block"/>
      </span>

      <!-- H1, H2, ... -->
      <span class="ql-format">
        <button class="ql-header" value="1"/>
        <button class="ql-header" value="2"/>
      </span>

      <span class="ql-format">
        <!-- Add subscript and superscript buttons -->
        <button
            class="ql-script"
            value="sub"
        />
        <button
            class="ql-script"
            value="super"
        />
      </span>

      <!-- list -->
      <span class="ql-format">
        <button
            class="ql-list"
            value="ordered"
        />
        <button
            class="ql-list"
            value="bullet"
        />
      </span>

      <!-- Link -->
      <span class="ql-format">
        <button
            class="ql-link"
        />
      </span>

      <!-- indent -->
      <span class="ql-format">
        <button
            class="ql-indent"
            value="-1"
        />
        <button
            class="ql-indent"
            value="+1"
        />
      </span>

      <!-- direction -->
      <span class="ql-format">
        <button
            class="ql-direction"
            value="rtl"
        />
      </span>

      <!-- heading ... -->
      <span class="ql-format">
        <select class="ql-header">
          <option value="1" selected>{{ $t('quill.header.1') }}</option>
          <option value="2">{{ $t('quill.header.2') }}</option>
          <option value="3">{{ $t('quill.header.3') }}</option>
          <option value="4">{{ $t('quill.header.4') }}</option>
          <option value="5">{{ $t('quill.header.5') }}</option>
          <option value="6">{{ $t('quill.header.6') }}</option>
        </select>
      </span>

      <!-- Add font size dropdown -->
      <span class="ql-format">
        <select class="ql-size">
          <option value="small">{{ $t('quill.size.small') }}</option>
          <option value="normal" selected>{{ $t('quill.size.normal') }}</option>
          <option value="large">{{ $t('quill.size.large') }}</option>
          <option value="huge">{{ $t('quill.size.huge') }}</option>
        </select>
      </span>

      <!-- Color -->
      <span class="ql-format">
        <select class="ql-color">
          <option selected="selected"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
        <select class="ql-background">
          <option value="#000000"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option selected="selected"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>

      <!-- Align -->
      <span class="ql-format">
        <select class="ql-align">
          <option selected="selected"></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>

      <!-- Clean -->
      <span class="ql-format">
        <button
            class="ql-clean"
        />
      </span>

      <!-- Link -->
      <span class="ql-format">
        <button
            class="ql-link"
        />
      </span>

    </div>
  </quill-editor>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  props: ['value'],
  data() {
    return {
      editorValue: '',
      editorOptions: {
        placeholder: 'szöveg ...',
        syntax: true,
        modules: {
          toolbar: '',
        },
      },
      id: null,
    }
  },
  watch: {
    value: {
      handler(parentDateValue) {
        this.editorValue = parentDateValue
      },
      deep: true,
    },
    editorValue: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true,
    },
  },
  created() {
    const maxNumber = 1000000
    this.id = Math.floor(Math.random() * maxNumber) + 1
    this.editorOptions.modules.toolbar = this.getId(true)
  },
  methods: {
    getId(withPrefix = false) {
      let prefix = '';

      if (withPrefix) {
        prefix = '#'
      }

      return prefix+'qlEditorToolbar'+this.id
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>