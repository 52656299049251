var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"xs":"12"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.modify.emailTemplate'))+" ")])],1)],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.formData.description)+" ")])],1),_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.subject')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.subject').toLowerCase(),"rules":"required","vid":"subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.subject')},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.greeting')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.greeting').toLowerCase(),"rules":"required","vid":"greeting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.greeting')},model:{value:(_vm.formData.greeting),callback:function ($$v) {_vm.$set(_vm.formData, "greeting", $$v)},expression:"formData.greeting"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.content')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.content').toLowerCase(),"rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),(_vm.formData.hasAction)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.actionText')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.actionText').toLowerCase(),"rules":"required","vid":"actionText"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.actionText')},model:{value:(_vm.formData.actionText),callback:function ($$v) {_vm.$set(_vm.formData, "actionText", $$v)},expression:"formData.actionText"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3509433906)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-id":parseInt(this.$route.params.id)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }