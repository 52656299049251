<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.emailTemplate') }}
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ formData.description }}
      </b-card-text>
    </b-card>

    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.subject')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.subject').toLowerCase()"
                    rules="required"
                    vid="subject"
                >
                  <b-form-input
                      v-model="formData.subject"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.subject')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.greeting')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.greeting').toLowerCase()"
                    rules="required"
                    vid="greeting"
                >
                  <b-form-input
                      v-model="formData.greeting"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.greeting')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.content')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.content').toLowerCase()"
                    rules="required"
                    vid="content"
                >
                  <editor
                      v-model="formData.content"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="formData.hasAction" md="12">
              <b-form-group
                  :label="$t('form.actionText')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.actionText').toLowerCase()"
                    rules="required"
                    vid="actionText"
                >
                  <b-form-input
                      v-model="formData.actionText"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.actionText')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BCard, BCardTitle, BCardText
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_emailTemplate/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import variables from '@/views/pages/_components/_helper/variables.vue'
import editor from '@/views/pages/_components/editor.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardText,
    vSelect,
    tabs,
    editor,
    cancelAndGOTOPreviousPage,
    variables
  },
  data() {
    return {
      role: 'emailTemplate',
      formData: {
        id: null,
        title: '',
        subject: '',
        greeting: '',
        content: '',
        description: '',
        variables: '',
        hasAction: false,
        actionText: ''
      },
      required,
      email,
    }
  },
  created() {
    this.$store.dispatch('fetchEmailTemplate', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id
    }).catch(error => {
      this.$helpers.showErrorToast(error.response.data.message)
      this.$acl.canListPermission(this.role)
          ? this.$router.push({name: 'email-templates'})
          : this.$router.go(-1)
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateEmailTemplate', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'email-templates' })
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
